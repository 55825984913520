@import url("/node_modules/swiper/swiper-bundle.css");
@import url("/node_modules/vanilla-cookieconsent/dist/cookieconsent.css");
@import url("/node_modules/vanilla-cookieconsent/dist/css-components/dark-scheme.css");
@import url("/node_modules/flowbite/dist/flowbite.css");

@tailwind base;
@tailwind components;

*,
*::after,
*::before {
  @apply box-border;
}

/**  Smooth Scrolling  */

html.lenis,
html.lenis body {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-smooth iframe {
  pointer-events: none;
}

/**  Basics  */

html,
body {
  @apply min-h-full;
}

body {
  @apply antialiased font-proxima -tracking-tight font-normal text-base leading-normal text-white bg-rb_blue_dark;
}

strong,
b {
  @apply font-semibold;
}

em {
  @apply italic;
}

/**  Bar Link  */

.link {
  @apply relative inline-block hover:text-rb_red p-0 m-0;
}

.link::before {
  @apply absolute h-[1px] w-full top-[100%] left-0 pointer-events-none bg-rb_red;
  content: "";
  transform-origin: 100% 50%;
  transform: scale3d(0, 1, 1);
  transition: transform 0.3s;
}

.link:hover::before {
  transform-origin: 0% 50%;
  transform: scale3d(1, 1, 1);
}

/** Overrides  */

.home,
.page-id-25,
.error404 {
  @apply !pt-0;
}

/**  Breadcrumbs  */

#breadcrumbs {
  @apply flex justify-start w-full relative items-center text-xs md:text-sm font-semibold font-kallisto mb-8;
}

#breadcrumbs span {
  @apply inline-flex;
}

#breadcrumbs .breadcrumb__divider {
  @apply text-rb_red px-2 translate-y-0.5;
}

#breadcrumbs a {
  @apply text-white hover:text-rb_red inline-block relative;
}

#breadcrumbs a::before {
  @apply absolute h-[2px] w-full top-[100%] left-0 pointer-events-none bg-rb_red;
  content: "";
  transform-origin: 100% 50%;
  transform: scale3d(0, 1, 1);
  transition: transform 0.3s;
}

#breadcrumbs a:hover::before {
  transform-origin: 0% 50%;
  transform: scale3d(1, 1, 1);
}

.breadcrumb_last {
  @apply text-white/30;
}

/**  Primary Menu  */

.nav-primary {
  @apply relative ml-auto text-xs tracking-widest font-semibold font-kallisto uppercase;
}

.nav-primary ul {
  @apply flex items-center gap-8;
}

.nav-primary li {
  @apply block;
}

.nav-primary a {
  @apply relative inline-block text-white hover:text-rb_red;
}

.nav-primary a::before {
  @apply absolute h-[1px] w-full top-[100%] left-0 pointer-events-none bg-rb_red;
  content: "";
  transform-origin: 100% 50%;
  transform: scale3d(0, 1, 1);
  transition: transform 0.3s;
}

li.menu-item-has-children {
  @apply relative;
}

li.menu-item-has-children a {
  @apply inline-flex items-center justify-between gap-1;
}

.nav-primary ul ul {
  @apply bg-black/80 divide-y m-0 p-0 divide-white/10 absolute top-5 left-0 w-64 hidden;
}

.nav-primary ul ul li {
  @apply block text-left m-0 p-0;
}

.nav-primary ul li:hover ul {
  @apply block;
}

.nav-primary ul ul li a {
  @apply px-4 py-2  w-full block m-0 text-left;
}

.nav-primary a:hover::before {
  transform-origin: 0% 50%;
  transform: scale3d(1, 1, 1);
}

.current-menu-item a {
  @apply text-rb_red;
}

.current-menu-item a::before {
  transform-origin: 0% 50%;
  transform: scale3d(1, 1, 1);
}

/**  Mobile Menu  */

.nav-mobile-container {
  @apply fixed top-0 left-0 h-full bg-black w-full max-w-xs z-[9999] py-20 px-8 -translate-x-full invisible pointer-events-none opacity-0;
}

.nav-mobile {
  @apply text-lg tracking-widest font-semibold font-kallisto uppercase text-white;
}

.nav-mobile ul {
  @apply flex flex-col items-start gap-4 z-0;
}

.nav-mobile a {
  @apply relative inline-block text-white hover:text-rb_red;
}

/**  Gravity Forms Styling */

body
  #gform_wrapper_1
  .gform_body
  .gform_fields
  .gfield
  .ginput_container_text
  input,
body
  #gform_wrapper_1
  .gform_body
  .gform_fields
  .gfield
  .ginput_container_email
  input,
body
  #gform_wrapper_1
  .gform_body
  .gform_fields
  .gfield
  .ginput_container_textarea
  textarea {
  @apply !bg-rb_blue_light text-white border-none;
}

body
  #gform_wrapper_1
  .gform_body
  .gform_fields
  .gfield
  .gfield_validation_message {
  @apply p-0 !bg-transparent border-none text-rb_red text-xs font-bold;
}

body #gform_wrapper_1 .gform_validation_errors {
  @apply p-4 border-none bg-rb_red !text-white text-sm mb-8;
}

body #gform_wrapper_1 .gform_validation_errors .gform_submission_error {
  @apply !text-inherit;
}

body #gform_wrapper_1 .gform_footer input[type="submit"] {
  @apply inline-block py-2 px-5 rounded-full bg-rb_red text-white font-bold font-kallisto hover:bg-black cursor-pointer mx-auto;
}

body #gform_wrapper_1 .gfield_required_text {
  @apply !not-italic !text-white/30;
}

body #gform_wrapper_1 .gform_footer {
  @apply !text-center;
}

#gform_confirmation_message_1 {
  @apply !font-bold !text-center;
}

/** Swiper */

.swiper-button-next,
.swiper-button-prev {
  @apply !text-rb_red;
}

.page-template-template-lander {
  @apply !pt-[50px] lg:!pt-[128px];
}

body #gform_wrapper_1 .gform_validation_errors .gform_submission_error {
  @apply pl-10;
}

section {
  @apply relative;
}

@tailwind utilities;
